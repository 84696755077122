// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	namespace: 'Development',
	production: false,
	// apiUrl: "http://localhost:8000",
	// gatewayUrl: "http://localhost:4100",
	apiUrl: 'https://develop.api.orka.works',
	gatewayUrl: 'https://develop.gateway.orka.works',
	maintenanceUrl: 'https://develop.maintenance.orkaops.com/api/maintenance/company',
	// maintenanceUrl: "https://maintenance.orkaops.com/api/maintenance/company",
	// apiUrl: "https://api.orka.works/v10", //live
	// gatewayUrl: "https://gateway.orka.works",
	// apiUrl: "https://lumen-upgrade-5-8-dot-broadstone-servers.nw.r.appspot.com/",
	loggingUrl: 'https://log-parser.develop.orkaops.com/logs',
	loggingKey: '4388c7d1-5472-413a-a5eb-b1bc5940553d',
	intercom: 'tckiku1x',
	googleAnalyticsKey: 'UA-111341209-5',
	lookerMap: 'https://lookerstudio.google.com/embed/reporting/e6e75c4b-98a4-4ec6-af72-82aaea6f1faf/page/p_ziiismw6jd',
	hotJar: {
		hotJarKey: '1754209',
		hotJarPause: true,
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
